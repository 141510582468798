import React, { useEffect, useState } from 'react';

const Adsense = ({ slotId, adId, adStatusCallback, footerAd }) => {

    useEffect(() => {
        if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});

        let observer = null;

        if (adId) {
            const adElem = document.getElementById(adId);
            if (adElem) {
                const config = { attributes: true, childList: false, subtree: false };
    
                observer = new MutationObserver((mutationList, observer) => {
                    let mutated = mutationList.find(elem => { return elem.type === 'attributes' && elem.attributeName === 'data-ad-status' });
                    if (mutated) {
                        adStatusCallback(mutated.target.getAttribute('data-ad-status'));
                    }
                });
    
                observer.observe(adElem, config);
            }
        }
        
        return () => {
            if (observer) {
                observer.disconnect();
            }
        }
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <ins id={adId} className="adsbygoogle"
                style={footerAd ? {display: "flex", alignItems: "center", justifyContent: "center"} : { display: 'block' }}
                data-ad-client="ca-pub-2873705349997362"
                data-ad-slot={slotId}
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    );
};

export default Adsense;